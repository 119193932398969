.summary-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s ease;
    min-width: 200px;
    width:100%;
}

    .summary-card:hover {
        transform: translateY(-2px);
    }

    .summary-card.blue {
        background-color: #eaf3ff;
    }

    .summary-card.yellow {
        background-color: #fff4e1;
    }

    .summary-card.green {
        background-color: #e9f9e9;
    }

.summary-card-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.summary-card-text {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.count {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    text-align: left;
}

.label {
    font-size: 14px;
    margin:0;
}

.summary-card-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 62px;
    background-color: #ffffff;
    border-radius: 5px;
}  

/*responsiveness*/
@media (max-width: 600px) {
    .summary-card {
        padding: 8px 16px;
    }

    .summary-card-icon {
        width: 35px;
        height: 35px;
    }
    .count {
        font-size: 18px;
    }
}