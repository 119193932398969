.layout {
  display: flex;
  height: calc(100vh - 180px);
  width: calc(100vw - 100px);
  margin-top: 20px;
}

.main-content {
  padding: 0px 20px;
  flex-grow: 1;
  background-color: #fff;
  overflow-y: auto;
  text-align: unset;
}

/*responsiveness*/
@media (max-width: 600px) {
  .main-content {
    padding: 0px;
  }
}
