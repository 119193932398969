:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
}

button:hover {
  border-color: #646cff;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
  }
  a:hover {
    color: #747bff;
  }
}
