.sidebar {
  width: 250px;
  background-color: #f4f4f4;
  height: 100vh;
  padding: 20px;
}

.sidebar-header {
  font-size: 18px;
  font-weight: bold;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
}

.sidebar-menu li {
  margin: 20px 0;
  cursor: pointer;
}

.logout {
  position: absolute;
  bottom: 20px;
}

.logout a {
  text-decoration: none;
  color: blue;
}


